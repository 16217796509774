.path {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
}
.path::-webkit-scrollbar {
  height: 4px; /* Adjust the height as desired */
}

.path::-webkit-scrollbar-track {
  background: #f1f1f1; /* Change the background color */
  border-radius: 10px;
  overflow: hidden;
}

.path::-webkit-scrollbar-thumb {
  background: #c1c1c1; /* Change the thumb color */
  border-radius: 10px;
  overflow: hidden;
}

.path::-webkit-scrollbar-thumb:hover {
  background: #727272; /* Change the thumb color on hover */
}

.node {
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
  padding: 0.5rem 0;
}

.children {
  display: flex;
  flex-flow: column;
  justify-content: stretch;
}

.children button {
  text-align: left;
  margin: 0.125rem 0;
}

.wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: 250ms ease-in-out;
  overflow: hidden;
}
.wrapper.expanded {
  grid-template-rows: 1fr;
}

.results {
  min-height: 0;
  border-radius: 0.25rem;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-flow: row wrap;
}

.dash-bordered {
  border: 1px dashed gray;
  padding: 0.5rem 1.5rem;
}
