.map-container {
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 5px;
}

.locate-button,
.circle-button {
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 3px;
  background-color: white !important;
  border-radius: 2px;
  margin-right: 10px;
  appearance: none;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}
.circle-button {
  margin-bottom: 10px;
}

.locate-button img,
.circle-button img {
  width: 100%;
}
