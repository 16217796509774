/* .snapshot-generator{
    width: 1rem;
    height: 1rem;
} */
canvas {
  width: 0rem;
  height: 0rem;
}

.css-j72h7s .chat_container {
  background: rgb(36, 37, 38);
}
.css-j72h7s .chat_header,
.css-j72h7s .chat_footer,
.css-j72h7s .user_list {
  background: #5d6c7b;
}
.css-j72h7s .chat_header span,
.css-j72h7s .message_group,
.css-j72h7s .chat_footer .css-4g6ai3 svg {
  color: #fff;
}
.css-j72h7s .active.user_list {
  background: #2a88c3 !important;
}
.css-j72h7s .chat_footer button.react-input-emoji--button svg {
  fill: #fff;
}
.chat_header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}
.chat_footer {
  border-top: 1px solid rgba(0, 0, 0, 0.04);
}
.latestMsg span {
  text-transform: capitalize;
}

.active_loader{
    padding: 7px 41px !important;
}
.upload_per {
    color: #000000!important;
    font-weight: 500;
}
.tree ul ul {
  padding-left: 20px;
}
button.hide_block_btn:hover {
  background-color: #2a88c3;
}

button.plan_btn {
  margin-top: 15px;
}

button.plan_btn:hover {
  background-color: #2a88c3;
}