@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Manrope", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input#chat_input {
  padding: 12px;
}
.chat_receiver{
  width: 241px;
  padding: 10px;
  background: #5d6c7b;
  border-radius: 0px 10px 10px 10px;
  color: #fff;
}

.chat_send{
  width: 241px;
  padding: 7px;
  background: #2a88c3;
  border-radius: 10px 0px 10px 10px;
  color: #fff;
  word-wrap: break-word;
  line-break: anywhere;
}

#latestMsg{
  width: 200px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
}
.latestMsg p {
  display: flex;
  justify-content: space-between;
}
div#unReadCount {
  background: #2a88c3;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}
span#msgCount {
  position: relative;
  color: #fff;
}
.active.MuiBox-root.css-0 {
  background: #2a88c336;
}

.message_group{
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #c1c1c1;
  color: #353333;
}

/* Popup overlay */
.popup {
  display: none; /* Hidden by default */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8); /* Black background with opacity */
  z-index: 1000; /* On top of everything else */
}

/* Popup content */
.popup-content {
  position: relative;
  margin: 15% auto;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  background-color: #fff;
}

/* Close button */
.close {
  position: absolute;
  top: 10px;
  right: 25px;
  color: #000;
  font-size: 35px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: red;
  text-decoration: none;
  cursor: pointer;
}
.css-j72h7s .upgrade_message{
  color: #fff;
}